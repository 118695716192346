import posthog from "posthog-js"

export function usePostHog() {
    posthog.init(import.meta.env.VITE_POSTHOG_TOKEN, {
        api_host: "https://eu.i.posthog.com",
        capture_pageview: false
    })

    return {
        posthog
    }

}
