import { computed, ref } from "vue"
import { defineStore } from "pinia"
import useAxios from "@/composables/useAxios"

export const useSchoolMain = defineStore("schoolMain", () => {
    const administrativeBasicData = ref(JSON.parse(localStorage.getItem("schoolAdministrativeBasicData")) || null)
    const basicData = ref(JSON.parse(localStorage.getItem("schoolMainBasicData")) || null)
    const cfas = ref()
    const connectedOpcos = ref()
    const labels = ref()
    const opcos = ref()
    const preferences = ref({})
    const signatureUsage = ref()
    const stats = ref()
    const trainings = ref([])

    const getFormations = computed(() => {
        if (cfas.value) {
            return cfas.value.flatMap(cfa => cfa.trainings)
        }
        return null
    })

    const getGroupedOpcos = computed(() => {
        if (connectedOpcos.value) {
            return connectedOpcos.value.reduce((acc, item) => {
                if (!acc[item.cfa]) {
                    acc[item.cfa] = []
                }
                acc[item.cfa].push(item)
                return acc
            }, {})
        }
        return null
    })

    function $reset() {
        useSchoolMain().$patch({
            administrativeBasicData: null,
            basicData: null,
            cfas: null,
            connectOpcos: null,
            labels: null,
            opcos: null,
            stats: null
        })
    }

    async function connectOpco(userData) {
        await useAxios.post(`/administrative/opco/register-api-key`, userData)
        await fetchConnectedOpcos()
        //const response = await useAxios.post(`/administrative/opco/register-api-key`, userData)
        // const index = connectedOpcos.value.findIndex(item => item.opco.id === response.data.api_key.opco.id)
        // if (index > -1) {
        //     connectedOpcos.value[index] = response.data.api_key
        // } else {
        //     connectedOpcos.value.push(response.data.api_key)
        // }
    }

    async function createLabel(userData) {
        const response = await useAxios.post(`/school/company-labels/`, userData)
        labels.value.push(response.data.label)
    }

    async function deleteLabel(id) {
        await useAxios.delete(`/school/company-labels/${id}/`)
        const index = labels.value.findIndex(item => item.id === id)
        if (index > -1) {
            labels.value.splice(index, 1)
        }
    }

    async function disconnectOpco(userData) {
        await useAxios.delete("/administrative/opco/remove-api-key", { data: userData })
        await fetchConnectedOpcos()
        // const index = connectedOpcos.value.findIndex(item => item.opco.id === userData.opco)
        // if (index > -1) {
        //     connectedOpcos.value.splice(index, 1)
        // }
    }

    async function fetchAdministrativeBasicData() {
        const response = await useAxios.get(`/school/administrative-basic-data`)
        localStorage.setItem("schoolAdministrativeBasicData", JSON.stringify(response.data))
        administrativeBasicData.value = response.data
    }

    async function fetchBasicData() {
        const response = await useAxios.get(`/school/basic-data`)
        localStorage.setItem("schoolMainBasicData", JSON.stringify(response.data))
        basicData.value = response.data
    }

    async function fetchCfas() {
        const response = await useAxios.get(`/school/apprentice-training-centers`)
        cfas.value = response.data.results
    }

    async function fetchConnectedOpcos() {
        const response = await useAxios.get(`/administrative/opco/connected`)
        useSchoolMain().$patch({
            connectedOpcos: response.data.connected_opco
        })
    }

    async function fetchLabels() {
        const response = await useAxios.get(`/school/company-labels/`)
        useSchoolMain().$patch({
            labels: response.data.results
        })

    }

    async function fetchOpcos() {
        const response = await useAxios.get(`/administrative/opco/list`)
        useSchoolMain().$patch({
            opcos: response.data
        })

    }

    async function fetchPreferences(id) {
        const response = await useAxios.get(`/school/apprentice-training-centers/${id}`)
        preferences.value = response.data.apprentice_training_center.notification_preferences
    }

    async function fetchTrainings() {
        const response = await useAxios.get(`/school/trainings/`)
        trainings.value = response.data.results
    }

    async function fetchSignatureUsage() {
        const response = await useAxios.get(`/customer/signatures-consumption`)
        useSchoolMain().$patch({
            signatureUsage: response.data
        })
    }

    async function fetchStats() {
        const response = await useAxios.get(`/school/dashboard-statistics`)
        useSchoolMain().$patch({
            stats: response.data.statistics
        })
    }

    async function updatePreferences(id, payload) {
        await useAxios.patch(`/school/apprentice-training-centers/${id}/notification-preferences/`, payload)
        preferences.value = payload
    }

    return {
        $reset,

        administrativeBasicData,
        basicData,
        cfas,
        connectedOpcos,
        labels,
        opcos,
        preferences,
        signatureUsage,
        stats,
        trainings,

        getFormations,
        getGroupedOpcos,

        connectOpco,
        createLabel,
        deleteLabel,
        disconnectOpco,
        fetchAdministrativeBasicData,
        fetchBasicData,
        fetchCfas,
        fetchConnectedOpcos,
        fetchLabels,
        fetchOpcos,
        fetchPreferences,
        fetchSignatureUsage,
        fetchStats,
        fetchTrainings,
        updatePreferences
    }
})

