import TheHeader from "@/components/TheHeader.vue"
import TheSidebar from "@/components/TheSidebar.vue"
import TheBottomBar from "@/components/TheBottomBar.vue"

export default [
  /*{
    path: "/company",
    name: "company",
    components: {
      header: TheHeader,
      sidebar: TheSidebar,
      bottombar: TheBottomBar,
      default: () => import("@/views/companies/HomeView.vue")
    },
    meta: {
      title: "Tableau de bord - TU COMMENCES DEMAIN",
      requireLogin: true,
      accessibleBy: 1
    }
  },*/
  {
    path: "/company/profile",
    redirect: "/company/profile/info",
    name: "Company Profile",
    components: {
      header: TheHeader,
      sidebar: TheSidebar,
      bottombar: TheBottomBar,
      default: () => import("@/views/companies/profile/IndexView.vue")
    },
    children: [
      {
        path: "/company/profile/:tab",
        name: "Retrieve Company Profile",
        meta: {
          primaryTitle: "Votre profil - TU COMMENCES DEMAIN",
          subtitle: "Gérez et complétez votre profil",
          displayName: null,
          isTab: false
        },
        component: () => import("@/views/companies/profile/RetrieveView.vue")
      },
    ],
    meta: {
      title: "Votre profil - TU COMMENCES DEMAIN",
      requireLogin: true,
      accessibleBy: [1]
    }
  },
  {
    path: "/company/job-offers",
    name: "job-offers",
    redirect: "/company/job-offers/list",
    components: {
      header: TheHeader,
      sidebar: TheSidebar,
      bottombar: TheBottomBar,
      default: () => import("@/views/companies/offers/IndexView.vue")
    },
    children: [
      {
        path: "/company/job-offers/list",
        name: "List Job Offers",
        meta: {
          primaryTitle: "Vos offres",
          subtitle: "Toutes vos offres",
          displayName: "Offres d'emploi",
          isTab: true
        },
        component: () => import("@/views/companies/offers/ListView.vue")
      },
      {
        path: "/company/job-offers/new",
        name: "job-offers.new",
        meta: {
          primaryTitle: "Vos offres",
          subtitle: "Création d'une offre",
          displayName: "Ajouter une offre",
          isTab: true
        },
        component: () => import("@/views/companies/offers/CreateView.vue")
      },
      {
        path: "/company/job-offers/edit/:id",
        name: "job-offers.update",
        meta: {
          primaryTitle: "Vos offres",
          subtitle: "Modification d'une offre",
          displayName: "",
          isTab: false
        },
        component: () => import("@/views/companies/offers/EditView.vue")
      },
      {
        path: "/company/job-offer/:id",
        name: "Retrieve Job Offer",
        meta: {
          primaryTitle: "",
          subtitle: "Votre offre",
          displayName: "",
          isTab: false
        },
        component: () => import("@/views/companies/offers/RetrieveView.vue")
      },
      {
        path: "/company/job-offer/:id/applications",
        name: "Job Offer Applications",
        meta: {
          primaryTitle: "Vos offres",
          subtitle: "Candidatures",
          displayName: "",
          isTab: false
        },
        component: () => import("@/views/companies/offers/applications/ListView.vue")
      }
    ],
    meta: {
      title: "Vos offres - TU COMMENCES DEMAIN",
      requireLogin: true,
      accessibleBy: [1]
    }
  },
  {
    path: "/company/settings",
    name: "Company Settings",
    components: {
      header: TheHeader,
      sidebar: TheSidebar,
      bottombar: TheBottomBar,
      default: () => import("@/views/companies/SettingsView.vue")
    },
    meta: {
      title: "Paramètres - TU COMMENCES DEMAIN",
      primaryTitle: "Paramètres",
      subtitle: "Information",
      requireLogin: true,
      accessibleBy: [1]
    }
  },
]