import { createApp } from "vue"
import { createPinia } from "pinia"

import App from "./App.vue"
import router from "./router"

import posthog from "posthog-js"
import * as Sentry from "@sentry/vue"
import { setLocale } from "yup"
import { createVfm } from "vue-final-modal"
import VueAwesomePaginate from "vue-awesome-paginate"

import { clickOutside } from "@/directives/clickOutside"

import "./assets/base.css"
import "@vueform/multiselect/themes/tailwind.css"
import "@schedule-x/theme-default/dist/index.css"
import "vue-final-modal/style.css"

/* Yup Locale */
setLocale({
    mixed: {
        required: "Champ requis",
        notType: "Valeur invalide",
        notNull: "Champ requis",
    },
    object: {
        required: "Champ requis",
        notType: "Valeur invalide",
    },
    string: {
        email: "Adresse e-mail invalide"
    },
    number: {
        min: "Valeur invalide",
        max: "Valeur invalide",
        integer: "La valeur doit être entière",
        positive: "La valeur ne peut être négative ou nulle"
    }
})

const app = createApp(App)

if (import.meta.env.VITE_APP_NODE_ENV === "production") {
    posthog.init(import.meta.env.VITE_POSTHOG_TOKEN,
        {
            api_host: "https://eu.i.posthog.com"
        }
    )
}

Sentry.init({
    app,
    dsn: "https://7348f92311e6fa4a2ddb00066b873665@o4506593304051712.ingest.us.sentry.io/4506718446026752",

    environment: import.meta.env.VITE_APP_NODE_ENV,

    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
            networkDetailAllowUrls: ['https://api.tucommencesdemain.fr/api/v1/administrative/training-convention/validation'],
            networkRequestHeaders: [''],
            networkResponseHeaders: ['']
        }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: import.meta.env.VITE_APP_NODE_ENV === 'development' ? 0 : 0.75,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [
        /^https:\/\/app-staging\.tucommencessdemain\/.fr/,
        /^https:\/\/app\.tucommencessdemain\/.fr/
    ],

    // Capture Replay for 20% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: import.meta.env.VITE_APP_NODE_ENV === 'development' ? 0 : 0.3,
    replaysOnErrorSampleRate: import.meta.env.VITE_APP_NODE_ENV === 'development' ? 0 : 1.0,
})

const vfm = createVfm()
app.directive("click-outside", clickOutside)

app.use(createPinia())
app.use(router)
app.use(vfm)
app.use(VueAwesomePaginate)

app.mount('#app')
