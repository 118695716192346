import TheHeader from "@/components/TheHeader.vue"
import TheSidebar from "@/components/TheSidebar.vue"
import TheBottomBar from "@/components/TheBottomBar.vue"

export default [
  /*{
    path: "/",
    name: "Student Home",
    components: {
      header: TheHeader,
      sidebar: TheSidebar,
      bottombar: TheBottomBar,
      default: () => import("@/views/students/HomeView.vue")
    },
    meta: {
      title: "Tableau de bord - TU COMMENCES DEMAIN",
      requireLogin: true,
      accessibleBy: 0
    }
  },*/
  {
    path: "/profile",
    redirect: "/profile/info",
    name: "Student Profile",
    components: {
      header: TheHeader,
      sidebar: TheSidebar,
      bottombar: TheBottomBar,
      default: () => import("@/views/students/profile/IndexView.vue")
    },
    children: [
      {
        path: "/profile/:tab",
        name: "Retrieve Student Profile",
        meta: {
          primaryTitle: "Votre profil - TU COMMENCES DEMAIN",
          subtitle: "Gérez et complétez votre profil",
          displayName: null,
          isTab: false
        },
        component: () => import("@/views/students/profile/RetrieveView.vue")
      },
    ],
    meta: {
      title: "Votre profil - TU COMMENCES DEMAIN",
      requireLogin: true,
      accessibleBy: [0]
    }
  },
  /*{
    path: "/job-offers",
    name: "Student Job Board",
    redirect: "/job-offers/list",
    components: {
      header: TheHeader,
      sidebar: TheSidebar,
      bottombar: TheBottomBar,
      default: () => import("@/views/students/offers/IndexView.vue")
    },
    children: [
      {
        path: "/job-offers/list",
        name: "available-job-offers.list",
        meta: {
          primaryTitle: "Offres d'emploi",
          subtitle: "Les offres disponibles",
          displayName: "Offres d'emploi",
          isTab: true
        },
        component: () => import("@/views/students/offers/ListView.vue")
      },
      {
        path: "/job-offer/:id",
        name: "available-job-offer",
        meta: {
          primaryTitle: "",
          subtitle: "Visualisation d'une offre",
          displayName: null,
          isTab: false
        },
        component: () => import("@/views/students/offers/RetrieveView.vue")
      },
      {
        path: "/job-offers/pool",
        name: "available-job-offers.pools",
        meta: {
          primaryTitle: "Vos offres partagées",
          subtitle: "Offres proposées par votre établissement",
          displayName: "Offres partagées",
          isTab: true
        },
        component: () => import("@/views/students/offers/pools/ListView.vue")
      }
    ],
    meta: {
      title: "Offres d'emploi - TU COMMENCES DEMAIN",
      requireLogin: true,
      accessibleBy: 0
    }
  },*/
  /*{
    path: "/applications",
    name: "applications",
    redirect: "/applications/list",
    components: {
      header: TheHeader,
      sidebar: TheSidebar,
      bottombar: TheBottomBar,
      default: () => import("@/views/students/applications/IndexView.vue")
    },
    children: [
      {
        path: "/applications/list",
        name: "applications.list",
        meta: {
          primaryTitle: "Vos candidatures",
          subtitle: "Toutes vos candidatures",
          displayName: "Candidatures",
          isTab: true
        },
        component: () => import("@/views/students/applications/ListView.vue")
      }
    ],
    meta: {
      title: "Vos candidatures - TU COMMENCES DEMAIN",
      requireLogin: true,
      accessibleBy: 0
    }
  },*/
  {
    path: "/student/education",
    name: "student.education",
    redirect: "/student/education/calendar",
    components: {
      header: TheHeader,
      sidebar: TheSidebar,
      bottombar: TheBottomBar,
      default: () => import("@/views/students/education/IndexView.vue")
    },
    children: [
        {
          path: "/student/education/calendar",
          name: "student.education.calendar",
          meta: {
            primaryTitle: "Pédagogie",
            subtitle: "Calendrier de formation",
            isTab: true
          },
          component: () => import("@/views/students/education/calendar/ListView.vue")
        },
    ],
    meta: {
      title: "Pédagogie - TU COMMENCES DEMAIN",
      requireLogin: true,
      accessibleBy: [0]
    }
  },
  {
    path: "/settings",
    name: "student.settings",
    components: {
      header: TheHeader,
      sidebar: TheSidebar,
      bottombar: TheBottomBar,
      default: () => import("@/views/students/SettingsView.vue")
    },
    meta: {
      title: "Paramètres - TU COMMENCES DEMAIN",
      primaryTitle: "Paramètres",
      subtitle: "Vos informations",
      requireLogin: true,
      accessibleBy: [0]
    }
  },
]